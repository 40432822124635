import { createSelector } from 'reselect';

import { RegisterState } from './types';
import { RootState } from 'store';

const getRegisterState = (state: RootState): RegisterState => state.RegisterReducer;

const hasError = createSelector(
  [getRegisterState],
  (RegisterState: RegisterState): boolean => RegisterState.isError
);
const getMsg = createSelector(
  [getRegisterState],
  (RegisterState: RegisterState): string[] | null => RegisterState.msg
);

const isSuccess = createSelector(
  [getRegisterState],
  (RegisterState: RegisterState): boolean => RegisterState.success
);
const isLoading = createSelector(
  [getRegisterState],
  (RegisterState: RegisterState): boolean => RegisterState.isLoading
);

export default {
  hasError,
  getMsg,
  isLoading,
  isSuccess
};
