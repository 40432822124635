import { ColorMap, FilingType, FundamentalStatus } from './types';

export const FILING_TYPES_OPTIONS: FilingType = {
  '10-K/Q': ['10-K', '10-Q', '10-QT', '10-K/A', '10-Q/A', '10-KT', '10-KT/A'],
  '8-K': ['8-K', '8-K/A'],
  '6-K': ['6-K', '6-K/A'],
  '20-F': ['20-F', '20-F/A'],
  '40-F': ['40-F', '40-F/A'],
  'S-1': ['S-1', 'S-1/A', 'S-11'],
  'S-3': ['S-3', 'S-3/A'],
  'S-4': ['S-4', 'S-4/A'],
  'F-1': ['F-1', 'F-1/A'],
  '20FR12B': ['20FR12B', '20FR12B/A'],
  '424B3': ['424B3', '424B4', '424B1', '424B2', '424B5'],
  DRS: ['DRS', 'DRS/A'],
  'F-4': ['F-4', 'F-4/A'],
  '10-12B': ['10-12B', '10-12B/A'],
  PRIVATE: ['PRIVATE'],
  Transcript: ['Transcript']
};

export const PAGE_SIZE = 10;

export const COLORS_MAP: ColorMap = {
  [FundamentalStatus.NOT_RELEASED]: '#ef233c',
  [FundamentalStatus.PENDING]: '#ffd60a'
};

export const PUBLISHED_COLOR = '#FFF9A6';

export const FILLER_VALUE = 'FILLER_VALUE';

export const COMPOSITE = 'COMPOSITE';
export const LESS_THAN_30_MIN_COLOR = '#02c39a';

export const TYPE_MAP: { [key: string]: string } = {
  [FILLER_VALUE]: 'filler value',
  [COMPOSITE]: 'composite'
};
