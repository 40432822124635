import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from 'pages/Login';
import RegisterThankYouPopup from 'components/RegisterThankYouPopup';
import Models from 'pages/Models';
import Dashboard from 'pages/Dashboard';
import ThankyouVerifyEmail from 'pages/ThankyouVerifyEmail';
import ForgetPassword from 'pages/ForgetPassword';
import ErrorPage from 'components/ErrorPage';

import routes from './routes';
import Register from 'pages/Register';
import ResetPassword from 'pages/ResetPassword';
import TagViewer from 'pages/TagViewer';
import InquireHerePopup from 'pages/InquireHerePopup';
import TextFundamental from 'pages/TextFundamental';
import SignUp from 'pages/SignUp';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import { DefaultAuthenticatedRoute } from './DefaultAuthenticatedRoute';
import LiveModelPreview from 'pages/LiveModelPreview';
import RolledUpNumbers from '../../pages/RolledUpNumbers';

const Router: React.FC = () => (
  <Switch>
    <Route path={routes.ERROR_404}>
      <ErrorPage errorType="404" />
    </Route>
    <Route path={routes.ERROR_500}>
      <ErrorPage errorType="500" />
    </Route>
    <Route path={routes.ERROR_401}>
      <ErrorPage errorType="401" />
    </Route>
    <Route path={routes.ERROR_403}>
      <ErrorPage errorType="403" />
    </Route>
    {!USE_SSO_LOGIN && (
      <Route path={routes.SIGN_UP}>
        <SignUp />
      </Route>
    )}
    {!USE_SSO_LOGIN && (
      <Route path={routes.REGISTRATION}>
        <Register />
      </Route>
    )}
    {!USE_SSO_LOGIN && (
      <Route path={routes.THANKYOU_VERIFY_EMAIL}>
        <ThankyouVerifyEmail />
      </Route>
    )}
    {!USE_SSO_LOGIN && (
      <Route path={routes.FORGET_PASSWORD}>
        <ForgetPassword />
      </Route>
    )}
    {!USE_SSO_LOGIN && (
      <Route path={routes.RESET_PASSWORD}>
        <ResetPassword />
      </Route>
    )}
    <DefaultAuthenticatedRoute path={routes.Live_PREVIEW}>
      <LiveModelPreview />
    </DefaultAuthenticatedRoute>
    <DefaultAuthenticatedRoute path={routes.TAG_VIEWER}>
      <TagViewer />
    </DefaultAuthenticatedRoute>
    <DefaultAuthenticatedRoute path={routes.DOC_VIEWER}>
      <TagViewer isDocument />
    </DefaultAuthenticatedRoute>
    <DefaultAuthenticatedRoute path={routes.TEXT_FUNDAMENTAL}>
      <TextFundamental />
    </DefaultAuthenticatedRoute>
    <Route path={routes.MODELS}>
      <Models />
    </Route>
    <Route path={routes.INQUIRE_HERE_POPUP}>
      <InquireHerePopup />
    </Route>
    <DefaultAuthenticatedRoute path={routes.DASHBOARD}>
      <Dashboard />
    </DefaultAuthenticatedRoute>

    <DefaultAuthenticatedRoute path={routes.ROLLUP_NUMBERS}>
      <RolledUpNumbers />
    </DefaultAuthenticatedRoute>

    {!USE_SSO_LOGIN && !isLoggedIn() && (
      <Route path={routes.LOGIN}>
        <Login />
      </Route>
    )}

    <Route path={routes.REGISTER_THANKYOU_POPUP}>
      <RegisterThankYouPopup />
    </Route>

    <Route path={routes.HOME}>
      <Models />
    </Route>
  </Switch>
);
export default React.memo(Router);
