import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    minHeight: '10vh',
    maxHeight: '60vh',
    padding: 12
  },
  expandedIcon: {
    transform: 'rotate(0deg) !important'
  },
  title: {
    paddingLeft: 16,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium
  },
  noNotifications: {
    fontSize: theme.typography.pxToRem(14)
  },
  popOverRoot: {
    marginTop: 40
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  logsListContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  detailsButton: {
    marginLeft: 'auto'
  },
  expandWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  noNotificationsContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  seenNotification: {
    backgroundColor: '#f2f6fc'
  },
  unSeenNotificationTitle: {
    fontWeight: theme.typography.fontWeightBold
  }
}));
