import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import DownloadIcon from '@material-ui/icons/GetAppSharp';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import retroVideoThumb from 'assets/retroVideoThumb.png';
import onePager from 'assets/onePager.png';

import useLocal from 'localization';

import Props from './types';
import useStyles from './style';
import { Menu, MenuItem } from '@material-ui/core';

const ExcelPluginPopup: React.FC<Props> = ({
  isOpened,
  hasError,
  error,
  isSuccessMsi,
  isSuccessExe,
  isLoadingMsi,
  onSubmitMsi,
  onClose,
  excelVersion
}: Props) => {
  const classes = useStyles();

  const excelPluginTitle = useLocal('excel_plugin');
  const downloadExcelPluginMsi = useLocal('download_excel_plugin_msi');
  const instructionalVideoLabel = useLocal('instructional_video');
  const onePagerLable = useLocal('one_pager');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleDownloadMsi = (version: string, event: React.MouseEvent<HTMLLIElement>) => {
    handleClose(event);
    onSubmitMsi(version);
  };

  useEffect(() => {
    if (isSuccessMsi || isSuccessExe) {
      onClose();
    }
  }, [isSuccessMsi, isSuccessExe, onClose]);

  return (
    <Dialog
      fullWidth
      open={isOpened}
      onClose={onClose}
      data-testid="add-new-model-popup"
      maxWidth="sm"
    >
      <DialogTitle disableTypography className={classes.title}>
        <h2>{excelPluginTitle}</h2>
        <IconButton onClick={onClose} data-testid="add-new-model-close-btn">
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contant}>
        {hasError && (
          <Alert data-testid="add-new-model-error" severity="error">
            {error}
          </Alert>
        )}

        <Box display="flex" py={1} justifyContent="space-between">
          <Box>
            <Box fontSize={18} py={1}>
              {instructionalVideoLabel}
            </Box>
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=smUB76NzMIQ">
              <img src={retroVideoThumb} alt={instructionalVideoLabel} />
            </a>
          </Box>
          <Box>
            <Box fontSize={18} py={1}>
              {onePagerLable}
            </Box>
            <a target="_blank" rel="noreferrer" href="https://docs.daloopa.com/docs/retrofitter">
              <img src={onePager} alt={onePagerLable} />
            </a>
          </Box>
        </Box>

        <DialogActions className={classes.action}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            data-testid="downloadMsi"
            id="downloadMsi"
            disabled={isLoadingMsi}
            onClick={handleClick}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <DownloadIcon />
            {downloadExcelPluginMsi}
            {isLoadingMsi && <CircularProgress size={24} />}
            {!isLoadingMsi && !!excelVersion && (
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                {excelVersion?.split(',').map((version) => (
                  <MenuItem
                    onClick={(e) => handleDownloadMsi(version, e)}
                    key={version}
                  >{`v ${version}`}</MenuItem>
                ))}
              </Menu>
            )}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ExcelPluginPopup;
