import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import PasswordStrengthBar from 'react-password-strength-bar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { History } from 'core';
import routes from 'components/Router/routes';
import useLocal from 'localization';
import { Link } from 'react-router-dom';

import { RegisterSelectors } from './duck';
import { RegisterTypes } from './duck';
import { actions } from './duck';
import useStyles from './style';
import Mixpanel from 'mixPanel';
import { isEmpty } from 'lodash';
import { validateComplexity, validateIdenticalCharacters } from '../../utils/passwordValidators';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Register: React.FC<RegisterTypes.RegisterProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messages = useSelector(RegisterSelectors.getMsg);
  const hasError = useSelector(RegisterSelectors.hasError);
  const isLoading = useSelector(RegisterSelectors.isLoading);

  const isSuccess = useSelector(RegisterSelectors.isSuccess);

  const firstNameRequired = useLocal('first_name_required');
  const lastNameRequired = useLocal('last_name_required');
  const phoneNotValid = useLocal('phone_not_valid');
  const passwordRequired = useLocal('password_required');
  const passwordCharacters = useLocal('password_characters');
  const passwordMax = useLocal('password_max');
  const passwordConsecutiveIdentical = useLocal('password_consecutive_identical');
  const passwordComplexity = useLocal('password_complexity');
  const passwordMatch = useLocal('password_match');
  const confirmPassword = useLocal('confirm_password_required');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const signupObjectShape = Yup.object({
    firstName: Yup.string().required(firstNameRequired),
    lastName: Yup.string().required(lastNameRequired),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string().matches(phoneRegExp, phoneNotValid),
    password: Yup.string()
      .required(passwordRequired)
      .min(8, passwordCharacters)
      .max(128, passwordMax)
      .test(
        'consecutiveIdentical',
        passwordConsecutiveIdentical,
        (password) => password === undefined || validateIdenticalCharacters(password)
      )
      .test({
        name: 'complexity',
        test: (value, context) => {
          if (value === undefined) {
            return true;
          }
          const errorMessage = validateComplexity(value, passwordComplexity);
          if (errorMessage) {
            return context.createError({ message: errorMessage });
          }
          return true;
        }
      }),
    cnfPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], passwordMatch)
      .required(confirmPassword)
      .min(8, passwordCharacters)
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    cnfPassword: '',
    phoneNumber: ''
  };
  const formikObj = useFormik<RegisterTypes.registerField>({
    initialValues,
    validateOnBlur: true,
    validationSchema: signupObjectShape,
    onSubmit: ({ firstName, lastName, email, password, phoneNumber }) => {
      Mixpanel.track('Register:new_user_signup', { firstName, lastName, email, phoneNumber });
      dispatch(actions.registerTrial(firstName, lastName, email, password, phoneNumber));
    }
  });

  return (
    <form onSubmit={formikObj.handleSubmit}>
      <Dialog maxWidth="xs" fullWidth open={true} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title} id="form-dialog-title">
          <h2>{useLocal('registration')}</h2>
          <IconButton
            data-testid="api-settings-close-btn"
            onClick={() => {
              window.location.replace('https://daloopa.com/plg');
            }}
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        {((formikObj.dirty && hasError && !isEmpty(messages)) || isSuccess) && (
          <Alert
            severity={hasError ? 'error' : 'success'}
            data-testid="server-error"
            classes={{ message: classes.alertMessage }}
          >
            {messages?.map((message, i) => (
              <div key={i}>&bull; {message}</div>
            ))}
          </Alert>
        )}
        <DialogContent>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <FormControl className={classes.formFirstName} variant="outlined">
              <InputLabel htmlFor="text" error={false}>
                {useLocal('first_name')}
              </InputLabel>
              <OutlinedInput
                id="name"
                inputProps={{ 'data-testid': 'register-email' }}
                name="firstName"
                type="text"
                labelWidth={78}
                onChange={formikObj.handleChange}
                value={formikObj.values.firstName}
                onBlur={formikObj.handleBlur}
                error={Boolean(formikObj.errors.firstName && formikObj.touched.firstName)}
              />
              <FormHelperText
                data-testid="email-error"
                error={Boolean(formikObj.errors.firstName && formikObj.touched.firstName)}
              >
                {formikObj.touched.firstName && formikObj.errors.firstName}
              </FormHelperText>
            </FormControl>

            <FormControl className={classes.formLastName} variant="outlined">
              <InputLabel htmlFor="text" error={false}>
                {useLocal('last_name')}
              </InputLabel>
              <OutlinedInput
                id="lastname"
                inputProps={{ 'data-testid': 'register-email' }}
                name="lastName"
                type="text"
                labelWidth={78}
                onChange={formikObj.handleChange}
                value={formikObj.values.lastName}
                onBlur={formikObj.handleBlur}
                error={Boolean(formikObj.errors.lastName && formikObj.touched.lastName)}
              />
              <FormHelperText
                data-testid="email-error"
                error={Boolean(formikObj.errors.lastName && formikObj.touched.lastName)}
              >
                {formikObj.touched.lastName && formikObj.errors.lastName}
              </FormHelperText>
            </FormControl>
          </Box>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="email" error={false}>
              {useLocal('register_email')}
            </InputLabel>
            <OutlinedInput
              id="email"
              inputProps={{ 'data-testid': 'register-email' }}
              name="email"
              type="text"
              labelWidth={111}
              onChange={formikObj.handleChange}
              value={formikObj.values.email}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.email && formikObj.touched.email)}
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.email && formikObj.touched.email)}
            >
              {formikObj.touched.email && formikObj.errors.email}
            </FormHelperText>
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="number" error={false}>
              {useLocal('phone_number')}
            </InputLabel>
            <OutlinedInput
              id="phoneNumber"
              inputProps={{ 'data-testid': 'phone_number' }}
              name="phoneNumber"
              type="text"
              labelWidth={103}
              onChange={formikObj.handleChange}
              value={formikObj.values.phoneNumber}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.phoneNumber && formikObj.touched.phoneNumber)}
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.phoneNumber && formikObj.touched.phoneNumber)}
            >
              {formikObj.touched.phoneNumber && formikObj.errors.phoneNumber}
            </FormHelperText>
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="password" error={false}>
              {useLocal('register_password')}
            </InputLabel>
            <OutlinedInput
              id="password"
              inputProps={{ 'data-testid': 'signup-password' }}
              name="password"
              type={passwordVisible ? '' : 'password'}
              labelWidth={70}
              onChange={formikObj.handleChange}
              value={formikObj.values.password}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.password && formikObj.touched.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.password && formikObj.touched.password)}
            >
              {formikObj.touched.password && formikObj.errors.password}
            </FormHelperText>
            {formikObj?.values?.password && isEmpty(formikObj?.getFieldMeta('password').error) && (
              <PasswordStrengthBar password={formikObj.values.password} />
            )}
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="password" error={false}>
              {useLocal('register_cnf_password')}
            </InputLabel>
            <OutlinedInput
              id="cnfPassword"
              inputProps={{ 'data-testid': 'signup-password' }}
              name="cnfPassword"
              type="password"
              labelWidth={130}
              onChange={formikObj.handleChange}
              value={formikObj.values.cnfPassword}
              onBlur={formikObj.handleBlur}
              error={Boolean(formikObj.errors.cnfPassword && formikObj.touched.cnfPassword)}
            />
            <FormHelperText
              data-testid="email-error"
              error={Boolean(formikObj.errors.cnfPassword && formikObj.touched.cnfPassword)}
            >
              {formikObj.touched.cnfPassword && formikObj.errors.cnfPassword}
            </FormHelperText>
          </FormControl>

          <DialogActions className={classes.dialogActions}>
            <Button
              disabled={isLoading || !formikObj.isValid}
              onClick={formikObj.submitForm}
              type="submit"
              color="primary"
              data-testid="signup-submit"
              variant="contained"
            >
              {useLocal('register')}
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
          </DialogActions>
          <Box py={2}>
            <Typography color="textSecondary">
              {useLocal('already_registered')}
              <Link to={routes.LOGIN}>{useLocal('login')}</Link>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </form>
  );
};

export default Register;
