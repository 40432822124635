import { takeLatest } from 'redux-saga/effects';
import { GraphQL, gql, History } from 'core';
import { selectState } from 'store';
import actionTypes, { requestNewModelAction } from './types';

import { getLocalValue } from 'localization';

function* newModelRequestSaga({ ticker, companyName }: ReturnType<requestNewModelAction>) {
  try {
    const {
      data: {
        createNewCompanyModelRequest: { success }
      }
    } = yield GraphQL.mutate({
      mutation: gql`
        mutation createNewModelRequestMutation($companyName: String!, $ticker: String!) {
          createNewCompanyModelRequest(companyName: $companyName, ticker: $ticker) {
            success
          }
        }
      `,
      variables: {
        ticker,
        companyName
      },
      fetchPolicy: 'no-cache'
    });
    if (success) {
      History.go(-2);
      const msg: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'request_success')
      );
      alert(msg);
    }
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    History.go(-2);
    alert(errorMsg);
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.MODEL_NEW_REQUEST, newModelRequestSaga);
}
