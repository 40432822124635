import React from 'react';

import useLocal from 'localization';

import Props from './types';
import useStyles from './style';
import thankyouVerifyEmail from '../../../src/assets/thankyouVerifyEmail.svg';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';

const VerifyEmailSuccess: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleLogin = () => {
    history.push('/login');
  };

  return (
    <>
      <img
        alt="Download Excel file"
        className={classes.thankyouVerifyEmail}
        src={thankyouVerifyEmail}
      />
      <Typography component="h1" className={classes.h1}>
        {useLocal('thankyou_verified_success')}
      </Typography>
      <Typography className={classes.h2}>{useLocal('you_may_now_login')}</Typography>
      <Button
        type="submit"
        color="primary"
        onClick={handleLogin}
        data-testid="login-submit"
        variant="outlined"
      >
        {useLocal('login')}
      </Button>
    </>
  );
};

export default VerifyEmailSuccess;
