import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListIcon from '@material-ui/icons/List';
import GridOnIcon from '@material-ui/icons/GridOn';

import Mixpanel from 'mixPanel';

import Box from '@material-ui/core/Box';
import Props from './types';
import useStyles from './style';

const ModelHeaderActions: React.FC<Props> = ({ showList, setShowList }: Props) => {
  const classes = useStyles();

  return (
    <Box pt={1} px={1} display={{ sm: 'none', xs: 'none', md: 'block', lg: 'block', xl: 'block' }}>
      <Tabs value={showList ? 1 : 0}>
        <Tab
          className={classes.iconLink}
          data-testid="grid-btn"
          onClick={() => {
            setShowList(false);
            Mixpanel.track('Marketplace:grid_view');
          }}
          selected={!showList}
          icon={<GridOnIcon />}
        />
        <Tab
          className={classes.iconLink}
          data-testid="list-btn"
          onClick={() => {
            setShowList(true);
            Mixpanel.track('Marketplace:list_view');
          }}
          selected={showList}
          icon={<ListIcon />}
        />
      </Tabs>
    </Box>
  );
};

export default React.memo(ModelHeaderActions);
