import React, { useEffect } from 'react';

import useLocal from 'localization';

import ModelListSkeleton from 'components/ModelListSkeleton';
import ModelList from 'components/ModelList';

import Props from './types';
import NotSubscribed from 'components/NotSubscribed';
import { Box } from '@mui/material';
import { actions } from '../../pages/Models/duck';
import { useDispatch } from 'react-redux';
import { isLoggedIn } from 'core/modules/auth.utils';

const MyModelList: React.FC<Props> = ({ isLoading, showList, myModel }: Props) => {
  const subscribed = useLocal('subscribed');
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(actions.getMyModels());
    }
  }, []);

  const myModelData = {
    industry: {
      id: 'my_model',
      name: subscribed
    },
    numberOfModelsInIndustry: myModel?.length ?? 0,
    clientModels: myModel
  };

  return (
    <Box>
      {isLoading && !myModel ? (
        <ModelListSkeleton numberOfIndustry={1} numberOfModel={30} showList={showList} />
      ) : myModelData?.clientModels?.length ? (
        <ModelList
          key={`${myModelData?.industry?.id}-${myModel?.length}`}
          {...myModelData}
          showList={showList}
          numberOfIndustriesByDefault={myModel?.length ?? 0}
          isIndustryModelLoading={null}
          maxSubscriptions={0}
          subscribedCount={0}
        />
      ) : (
        <NotSubscribed />
      )}
    </Box>
  );
};

export default React.memo(MyModelList);
