import actionTypes, {
  getUserDetailsAction,
  setUserDetailsErrorAction,
  setUserDetailsPendingAction,
  setUserTypeAction,
  getFreeTierUserDetailsAction,
  setUserDetailsAction
} from './types';

const getUserDetails: getUserDetailsAction = () => ({
  type: actionTypes.GET_USER_DETAILS
});

const getFreeTierUserDetails: getFreeTierUserDetailsAction = () => ({
  type: actionTypes.GET_FREE_TIER_USER_DETAILS
});

const setUserDetailsError: setUserDetailsErrorAction = (error) => ({
  type: actionTypes.SET_USER_DETAILS_ERROR,
  error
});

const setUserDetailsPending: setUserDetailsPendingAction = () => ({
  type: actionTypes.SET_USER_DETAILS_PENDING
});

const setUserDetailUserType: setUserTypeAction = (userTier) => ({
  type: actionTypes.SET_USER_TYPE,
  userTier
});

const setUserDetails: setUserDetailsAction = (userDetail) => ({
  type: actionTypes.SET_USER_DETAILS,
  userDetail
});

export default {
  getUserDetails,
  setUserDetailsError,
  setUserDetailsPending,
  setUserDetailUserType,
  getFreeTierUserDetails,
  setUserDetails
};
