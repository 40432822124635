export enum FundamentalStatus {
  NOT_RELEASED = 'NOT_RELEASED',
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  CLIENTVIEW = 'CLIENTVIEW'
}

export interface Document {
  filingType: string;
  url: string;
  createdAt: string;
}

export interface Fundamental {
  id: string;
  value: string;
  normalizedValue: string;
  period: string;
  context: string;
  status: FundamentalStatus;
  updatedAt: string;
  publishedAt: string;
  publishedIn: number;
  createdAt: string;
  document: Document;
  type: string;
  isResult?: boolean;
}

export interface Series {
  id: string;
  name: string;
  rowNumber: number;
  fullSeriesName: string;
  fundamentals: Fundamental[];
  document: Document;
}

export interface FundamentalByPeriod {
  [period: string]: Fundamental;
}

export interface SeriesWithFundamentalsByPeriod extends Omit<Series, 'fundamentals'> {
  fundamentals?: FundamentalByPeriod;
}

export interface Section {
  name: string;
  seriesIds: string[];
  id: string;
  isPublished: boolean;
  publishedFundamentalsCount: number;
  minimumPublishedIn: number;
}

export interface SectionById {
  [id: string]: SectionWithSeries;
}

export interface ModelLivePreviewSeries {
  series: Series[];
  periods: string[];
  updatedPeriods: string;
}

export interface PaginatedModelLivePreviewSeries {
  data: ModelLivePreviewSeries;
  page: number;
}

export interface Section {
  series: Series[];
  name: string;
  id: string;
}

export interface SectionWithSeries extends Omit<Section, 'series'> {
  series: SeriesWithFundamentalsByPeriod[];
}

export interface ColorMap {
  [status: string]: string;
}

export interface FundamentalFilters {
  query?: string;
  filingTypes?: string[];
  publishedOnly: boolean;
}

export interface FilingTypeFiltersProps {
  onFilter: (selectedFilingTypes: string[]) => void;
}

export interface CheckedFilingType {
  [key: string]: boolean;
}

export interface FilingType {
  [key: string]: string[];
}
