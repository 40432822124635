import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql } from 'core';
import actionTypes, { verifyEmailActionType } from './types';
import actions from './actions';
import { getLocalValue } from 'localization';
import { selectState } from 'store';
import Mixpanel from 'mixPanel';

export function* verifyEmailSaga({ uid, token }: ReturnType<verifyEmailActionType>) {
  yield put(actions.verifyEmailPending());

  try {
    const {
      data: {
        verifyEmail: { success, errors }
      }
    } = yield GraphQL.mutate({
      mutation: gql`
        mutation verifyEmailMutation($uid: String!, $token: String!) {
          verifyEmail(uid: $uid, token: $token) {
            success
            errors
          }
        }
      `,
      variables: { uid, token },
      fetchPolicy: 'no-cache'
    });

    if (success) {
      yield put(actions.verifyEmailSuccess());
      Mixpanel.track('Register:new_user_registered');
    } else if (errors && errors.length > 0) {
      const unknown: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'unknown')
      );
      switch (errors[0]) {
        case 'INVALID_TOKEN':
          const invalidToken: string = yield selectState<string | undefined>((state) =>
            getLocalValue(state, 'invalid_token')
          );
          yield put(actions.verifyEmailError(invalidToken));
          break;
        default:
          yield put(actions.verifyEmailError(unknown));
          break;
      }
    }
  } catch (error) {
    yield put(actions.verifyEmailError((error as { message: string })?.message));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.VERIFY_EMAIL, verifyEmailSaga);
}
