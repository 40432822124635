import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import withWidth from '@material-ui/core/withWidth';
import { isLoggedIn } from 'core/modules/auth.utils';
import { actions as modelActions, modelsSelectors as baseModelSelectors } from 'pages/Models/duck';
import { actions, modelsSelectors } from './duck';
import ModelListSkeleton from '../../components/ModelListSkeleton/ModelListSkeleton';
import ModelItem from '../../components/ModelItem';
import { UserDetailSelectors } from '../../userDetail';
import Mixpanel from '../../mixPanel';
import useLocal from '../../localization';
import useStyles from './style';

interface IndustryModelsProps {
  query: string;
}
const IndustryModels: React.FC<IndustryModelsProps> = ({ query }: IndustryModelsProps) => {
  const dispatch = useDispatch();

  const industryModels = useSelector(modelsSelectors.getIndustryModels);
  const isIndustryModelsLoading = useSelector(modelsSelectors.isIndustryModelsLoading);
  const isTrialTier = useSelector(UserDetailSelectors.isTrialTier);
  const isEnterpriseTier = useSelector(UserDetailSelectors.isEnterpriseTier);
  const industryModelTitle = useLocal('industry_model');
  const classes = useStyles();
  const downloadingModelId = useSelector(baseModelSelectors.latestDownloadingModelId);
  const latestModelStatus = useSelector(baseModelSelectors.latestModelStatus);

  useEffect(() => {
    if (isLoggedIn() && (isEnterpriseTier || isTrialTier)) {
      dispatch(actions.getIndustryModels(query));
    }
  }, [dispatch, query, isTrialTier, isEnterpriseTier]);

  const handleDownload = (modelId: string, industry: string, description: string) => {
    Mixpanel.track('Marketplace:download_industry_model', { modelId, industry, description });
    dispatch(modelActions.downloadModel(modelId, 'industry_model', industry));
  };

  return (
    <Box>
      {isIndustryModelsLoading ? (
        <ModelListSkeleton numberOfIndustry={1} numberOfModel={20} showList={false} />
      ) : (
        industryModels.length > 0 && (
          <Box border={1} borderColor="border.main">
            <Box
              fontWeight="fontWeightBold"
              fontSize={16}
              p={1}
              m={0}
              pl={3}
              bgcolor="headingBG.main"
              data-testid="industry-title"
            >
              {industryModelTitle} (
              <span data-testid="model-count-in-industry">{industryModels.length}</span>)
            </Box>
            <Box m={0} p={3}>
              <Grid container spacing={1} className={classes.wrapper}>
                {industryModels.map((item, index) => (
                  <ModelItem
                    type="INDUSTRY"
                    id={item.id}
                    showSubscribe={false}
                    showDownload={true}
                    isTrialTier={isTrialTier}
                    clientModelIndex={index}
                    key={`${item.id}`}
                    latestQuarter={item.latestQuarter}
                    title={item.description}
                    onDownload={() => handleDownload(item.id, item.industry, item.description)}
                    showUnsubscribe={false}
                    isDownloadLoading={
                      downloadingModelId === item.id && latestModelStatus === 'PENDING'
                    }
                  />
                ))}
              </Grid>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default withWidth()(IndustryModels);
