import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Menu as MetrialMenu } from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { History, redirectToLoginUrl, redirectToSSOUrl } from 'core';
import { Badge, Button } from '@material-ui/core';
import useLocal from 'localization';
import { SET_SHOW_TRANSLATION_KEYS } from 'localization/reducer';
import routes from 'components/Router/routes';
import ExcelIcon from 'components/ExcelIcon';
import { actions as authAction } from 'pages/Login/duck';
import useStyles from './style';
import { isLoggedIn, USE_SSO_LOGIN } from 'core/modules/auth.utils';
import Classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { UserDetailSelectors } from 'userDetail';
import WhiteTooltip from 'components/WhiteTooltip';
import ModelFreeTrierTooltip from 'components/ModelFreeTrierTooltip';
import { Notifications } from '@material-ui/icons';
import notificationsSelector from '../../pages/Models/duck/selector';
import EarningsUpdateActionNotifications from '../../components/EarningsUpdateActionNotifications';
import Mixpanel from 'mixPanel';

const Menu: React.FC<RouteComponentProps> = (history: RouteComponentProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const StyledTab = withStyles((theme: Theme) => ({
    wrapper: {
      flexDirection: 'row',
      background: theme.palette.primary.dark,
      justifyContent: 'space-evenly',
      width: '70%',
      paddingTop: theme.spacing(0.7),
      alignItems: 'flex-start'
    }
  }))(Tab);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const modelText = useLocal('model');
  const apiSettingsText = useLocal('api_settings');
  const kpiKey = useLocal('kpi');
  const supportText = useLocal('support');

  const logoutText = useLocal('logout');
  const loginText = useLocal('login');
  const pluginText = useLocal('plugin');
  const documentationText = useLocal('documentation');
  const isFreeTier = useSelector(UserDetailSelectors.isFreeTier);
  const earningsUpdateNotificationsUnSeenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );
  const isUpdateNotificationsLoading = useSelector(
    notificationsSelector.isUpdateNotificationsLoading
  );
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(anchorEl ? null : event.currentTarget);
    Mixpanel.track('marketplace:notification_bell_icon_click');
  };

  useEffect(() => {
    const parsed = queryString.parse(history.location.search);
    if (parsed['showTranslationKeys'] === 'true') {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: true
      });
    } else {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: false
      });
    }
  }, [history, dispatch]);

  return (
    <Tabs value={history.location.pathname !== routes.HOME ? history.location.pathname : false}>
      <Tab
        label={modelText}
        value={routes.MODELS}
        component={Link}
        to={routes.MODELS}
        className={classes.menuLink}
        data-testid="model-page-link"
      />
      <Tab
        label={supportText}
        className={classes.menuLink}
        onClick={() => window.open('mailto:support@daloopa.com')}
      />

      {isLoggedIn() ? (
        <Tab
          label={apiSettingsText}
          value={routes.API_SETTINGS}
          component={Link}
          to={routes.API_SETTINGS}
          className={classes.menuLink}
          data-testid="api-settings-link"
        />
      ) : (
        <Button
          variant={'text'}
          className={Classnames(classes.menuLink, classes.apiSettingsButton)}
          data-testid="api-settings-link"
          onClick={redirectToLoginUrl}
        >
          {apiSettingsText}
        </Button>
      )}
      <Tab
        label={kpiKey}
        value={process.env.REACT_APP_KPI_LINK}
        component={Link}
        to={{ pathname: process.env.REACT_APP_KPI_LINK }}
        className={classes.menuLink}
        data-kpi="kpi-link"
        target="_blank"
      />
      <Tab
        label={documentationText}
        value={process.env.REACT_APP_HELP_PAGE_LINK}
        component={Link}
        to={{ pathname: process.env.REACT_APP_HELP_PAGE_LINK }}
        className={classes.menuLink}
        data-testid="help-page-link"
        target="_blank"
      />
      {/* {isLogedin() && <Tab className={classes.iconLink} icon={<NotificationsIcon />} />} */}
      <Tab className={classes.iconLink} icon={<AccountCircleIcon />} onClick={handleClick} />

      {isLoggedIn() && !isUpdateNotificationsLoading && (
        <Tab
          className={classes.iconLink}
          icon={
            <Badge badgeContent={earningsUpdateNotificationsUnSeenCount} color={'error'}>
              <Notifications />
            </Badge>
          }
          onClick={handleNotificationClick}
        />
      )}

      <EarningsUpdateActionNotifications
        anchorEl={notificationAnchorEl}
        onClose={() => setNotificationAnchorEl(null)}
      />

      <MetrialMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {/* {isLogedin() && (
          <MenuItem component={Link} to={routes.DASHBOARD} onClick={handleClose}>
            {dashboardText}
          </MenuItem>
        )} */}
        {isLoggedIn() && (
          <MenuItem onClick={() => dispatch(authAction.logout())}>{logoutText}</MenuItem>
        )}
        {!isLoggedIn() && (
          <MenuItem
            onClick={() => {
              if (USE_SSO_LOGIN) {
                redirectToSSOUrl();
              } else {
                handleClose();
                History.push(routes.LOGIN);
              }
            }}
          >
            {loginText}
          </MenuItem>
        )}
      </MetrialMenu>
      {isFreeTier ? (
        <WhiteTooltip title={<ModelFreeTrierTooltip showNotAllowed />} interactive>
          <span>
            <StyledTab
              icon={<ExcelIcon />}
              label={pluginText}
              disabled
              onClick={() => History.push(routes.EXCEL_PLUGIN)}
            />
          </span>
        </WhiteTooltip>
      ) : (
        <StyledTab
          icon={<ExcelIcon />}
          label={pluginText}
          onClick={() => History.push(routes.EXCEL_PLUGIN)}
        />
      )}
    </Tabs>
  );
};
export default withRouter(Menu);
