import React, { useMemo } from 'react';

import Props from './types';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import notificationSelector from '../../pages/Models/duck/selector';
import notificationActions from '../../pages/Models/duck/actions';
import { Box, Button, CircularProgress, List, Popover, Typography } from '@material-ui/core';
import { EarningsUpdateActionNotificationList } from './EarningsUpdateActionNotificationList';
import useLocal from '../../localization';
import notificationsSelector from '../../pages/Models/duck/selector';
import Mixpanel from 'mixPanel';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

const EarningsUpdateActionNotifications: React.FC<Props> = ({ anchorEl, onClose }: Props) => {
  const classes = useStyles();
  const notificationsByCompanyId = useSelector(notificationSelector.earningsUpdateNotifications);
  const isMarkingAllNotificationsLoading = useSelector(
    notificationSelector.isMarkingAllNotificationsLoading
  );
  const earningsUpdateNotificationsUnSeenCount = useSelector(
    notificationsSelector.earningsUpdateNotificationsUnSeenCount
  );
  const title = useLocal('earning_action_notification_modal_title');
  const markAllSeen = useLocal('mark_all_seen');

  const dispatch = useDispatch();

  const { sortedNotifications, companyIds } = useMemo(() => {
    const sortedNotifications = Object.values(notificationsByCompanyId).sort((a, b) =>
      b?.updatedAt.localeCompare(a?.updatedAt)
    );
    const companyIds = Object.values(notificationsByCompanyId).map(
      (notification) => notification.company.id
    );
    return { sortedNotifications, companyIds };
  }, [notificationsByCompanyId]);

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;

    return (
      <EarningsUpdateActionNotificationList
        style={style}
        notificationForCompany={sortedNotifications[index]}
        key={`${index}`}
      />
    );
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{ root: classes.popOverRoot }}
    >
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          {earningsUpdateNotificationsUnSeenCount > 0 && (
            <Button
              variant={'text'}
              color={'primary'}
              onClick={() => {
                dispatch(notificationActions.markAllNotificationsSeen(companyIds));
                Mixpanel.track('marketplace:mark_all_as_read_notifications_click');
              }}
            >
              {isMarkingAllNotificationsLoading ? <CircularProgress /> : markAllSeen}
            </Button>
          )}
        </Box>
        <FixedSizeList
          height={0.6 * window.innerHeight}
          width={600}
          itemSize={60}
          itemCount={sortedNotifications.length}
        >
          {renderRow}
        </FixedSizeList>

        {sortedNotifications.length === 0 && (
          <Box className={classes.noNotificationsContainer}>
            <Typography className={classes.noNotifications}>{'No Notifications'}</Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default EarningsUpdateActionNotifications;
