import React, { useRef, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import useLocal from 'localization';

import SubHeader from 'components/SubHeader';
import TextFundamentalTypes from './duck';
import useStyles from './style';
import { Axios, History } from 'core';

const TextFundamental: React.FC<TextFundamentalTypes> = () => {
  const classes = useStyles();
  const iframe = useRef<HTMLIFrameElement>(null);
  const tagViewerLocal = useLocal('tag_viewer');
  const url = `${process.env.REACT_APP_BACKEND_URL}/text_fundamental${History.location.search}`;
  const [urlObject, setUrlObject] = useState<string>('');

  useEffect(() => {
    async function getTextFundamentalsSrc() {
      const response = await Axios.get(url);
      let content = (response as { data?: string })?.data ?? '';
      if (content) {
        content = content.replaceAll('/static/', `${process.env.REACT_APP_BACKEND_URL}/static/`);
        setUrlObject(content);
      }
    }
    getTextFundamentalsSrc();
  }, []);

  const onIframeLoad = () => {
    // load prime check from doppler
    if (!iframe.current) {
      return;
    }
    window.onmessage = function (e: MessageEvent) {
      if (e.data.type === 'linkClicked') {
        History.push(`/src/${e.data.id}`);
      }
    };
  };

  return (
    <Box pt={{ xs: 18, sm: 15 }}>
      <SubHeader title={tagViewerLocal} />
      <Container className={classes.container} maxWidth="xl" data-testid="page-container">
        <Box bgcolor="white.main" height="80vh">
          {urlObject && (
            <iframe
              ref={iframe}
              srcDoc={urlObject}
              className={classes.iframe}
              title="tag-viewer"
              onLoad={onIframeLoad}
              src={window.location.href}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default TextFundamental;
