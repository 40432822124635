import { string } from 'yup/lib/locale';
import actionTypes, { UserDetailState, actionCreatorTypes } from './types';

const initialState: UserDetailState = {
  isLoading: false,
  isError: false,
  error: null,
  userTier: null,
  maxSubscriptions: 0,
  allowedCompanies: null,
  allowedSector: null,
  allowedSectors: null,
  allowedIndustries: null,
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  subscribedCount: 0
};

const reducer = (
  state: UserDetailState = initialState,
  action: actionCreatorTypes
): UserDetailState => {
  switch (action.type) {
    case actionTypes.SET_USER_TYPE:
      return {
        ...state,
        isLoading: false,
        error: null,
        isError: false,
        userTier: action.userTier ?? null
      };

    case actionTypes.SET_USER_DETAILS_PENDING:
      return {
        ...state,
        isLoading: true,
        error: null,
        isError: false
      };

    case actionTypes.SET_USER_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error ?? null,
        isError: true
      };

    case actionTypes.SET_USER_DETAILS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isError: false,
        ...(action.userDetail ?? {})
      };
    default:
      return state;
  }
};

export default reducer;
