import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql } from 'core';
import actionTypes from './types';
import actions from './actions';
import { getLocalValue } from 'localization';
import { selectState } from 'store';
import Mixpanel from 'mixPanel';

export function* getUserDetailSaga() {
  yield put(actions.setUserDetailsPending());
  try {
    const {
      data: { userTier }
    } = yield GraphQL.query({
      query: gql`
        query userTierQuery {
          userTier
        }
      `,
      fetchPolicy: 'no-cache'
    });

    if (userTier) {
      yield put(actions.setUserDetailUserType(userTier));
      if (userTier === 'free') {
        yield put(actions.getFreeTierUserDetails());
      }
    }
  } catch (error) {
    const geniricError: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    const errorMsg = (error as { message: string })?.message ?? geniricError;
    yield put(actions.setUserDetailsError(errorMsg));
  }
}

export function* getFreeTierUserDetailSaga() {
  yield put(actions.setUserDetailsPending());
  try {
    const {
      data: { freeTierUsage }
    } = yield GraphQL.query({
      query: gql`
        query freeTierUsageQuery {
          freeTierUsage {
            maxSubscriptions
            allowedCompanies
            allowedSectors
            allowedIndustry
            firstName
            lastName
            email
            phoneNumber
            subscribedCount
          }
        }
      `,
      fetchPolicy: 'no-cache'
    });

    if (freeTierUsage) {
      yield put(actions.setUserDetails(freeTierUsage));
      Mixpanel.track('freeUser:logged_in', freeTierUsage);
    }
  } catch (error) {
    const geniricError: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    const errorMsg = (error as { message: string })?.message ?? geniricError;
    yield put(actions.setUserDetailsError(errorMsg));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.GET_USER_DETAILS, getUserDetailSaga);
  yield takeLatest(actionTypes.GET_FREE_TIER_USER_DETAILS, getFreeTierUserDetailSaga);
}
