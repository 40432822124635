import SignUpReducer from './reducer';
import SignUpSelectors from './selector';
import SignUpSaga from './saga';
import * as SignUpTypes from './types';
import actionTypes from './types';
import actions from './actions';

export { SignUpSelectors, SignUpSaga, SignUpTypes, actionTypes, actions };

export default SignUpReducer;
