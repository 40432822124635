import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router as ReactRouter } from 'react-router-dom';
import { SET_LANGUAGE } from './localization/reducer';
import Header from './components/Header';
import Router from './components/Router';
import Popup from 'components/Popup';
import { History } from 'core';
import InitializeIntercom from 'intercom';
import { isLoggedIn } from 'core/modules/auth.utils';
import { actions as userDetailAction } from 'userDetail';
import { queryClient } from 'core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LicenseInfo } from '@mui/x-license-pro';

const DATA_GRID_LICENSE = process.env.REACT_APP_DATA_GRID_LICENSE;
if (DATA_GRID_LICENSE) {
  LicenseInfo.setLicenseKey(DATA_GRID_LICENSE);
}
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: SET_LANGUAGE,
      lang: 'en'
    });
    if (isLoggedIn()) {
      dispatch(userDetailAction.getUserDetails());
    }
    if (process.env.REACT_APP_ENABLE_INTERCOM === 'true' && isLoggedIn()) {
      InitializeIntercom();
    }
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <React.Fragment>
        <CssBaseline />
        <ReactRouter history={History}>
          <Header />
          <Router />
          <Popup />
        </ReactRouter>
      </React.Fragment>
    </QueryClientProvider>
  );
}

export default App;
