import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql, History } from 'core';
import routes from 'components/Router/routes';
import actionTypes, { registerTrialAction } from './types';
import actions from './actions';
import { getLocalValue } from 'localization';
import { selectState } from 'store';

export function* registerTrialSaga({
  firstName,
  lastName,
  email,
  password,
  phoneNumber
}: ReturnType<registerTrialAction>) {
  yield put(actions.registerTrialPending());

  try {
    const {
      data: {
        createFreeTierUser: { success, errors }
      }
    } = yield GraphQL.mutate({
      mutation: gql`
        mutation createFreeTierUserMutation(
          $firstName: String!
          $lastName: String!
          $email: String!
          $password: String!
          $phoneNumber: String
          $url: String
        ) {
          createFreeTierUser(
            firstName: $firstName
            lastName: $lastName
            email: $email
            password: $password
            phoneNumber: $phoneNumber
            url: $url
          ) {
            success
            errors
          }
        }
      `,
      variables: {
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        url: window.location.href
      }
    });

    if (success) {
      yield put(actions.registerTrialSuccess());
      History.push(routes.REGISTER_THANKYOU_POPUP + `?email=${email}`);
    } else if (errors && errors.length > 0) {
      switch (errors[0]) {
        case 'DUPLICATE_EMAIL':
          const signupDuplicateEmail: string = yield selectState<string | undefined>((state) =>
            getLocalValue(state, 'signup_duplicate_email')
          );
          yield put(actions.registerTrialError([signupDuplicateEmail]));
          alert(signupDuplicateEmail);
          setTimeout(() => {
            History.push(routes.LOGIN);
          }, 500);
          break;
        case 'EMAIL_DOMAIN_NOT_ALLOWED':
          const emailError: string = yield selectState<string | undefined>((state) =>
            getLocalValue(state, 'email_domain_not_allowed')
          );
          yield put(actions.registerTrialError([emailError]));
          break;
        default:
          yield put(actions.registerTrialError(errors));
          break;
      }
    }
  } catch (error) {
    yield put(actions.registerTrialError([(error as { message: string })?.message]));
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.REGISTER_TRIAL, registerTrialSaga);
}
