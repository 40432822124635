import { gql } from 'core';

export const UPDATE_NOTIFICATION_SUBSCRIPTION = gql`
  subscription earningUpdateNotificationSubscription {
    earningUpdateNotifications(logsCount: 5) {
      notificationCount
      seen
      updatedAt
      company {
        id
        name
        ticker
        disabled
        favicon
      }
      logs {
        id
        period
        action
        count
        updatedAt
      }
    }
  }
`;

export const UPDATE_NOTIFICATIONS_QUERY = gql`
  query earningUpdateNotificationQuery {
    earningUpdateNotifications(logsCount: 5) {
      notificationCount
      updatedAt
      seen
      avgRtuUpdateTime
      company {
        id
        name
        ticker
      }
    }
  }
`;
