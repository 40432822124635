import {
  ModelLivePreviewSeries,
  PaginatedModelLivePreviewSeries
} from '../../LiveModelPreview/types';
import { gql } from '@apollo/client';
import { GraphQL } from 'core';

export const getFundamentalForSeries = async (
  companyId: string,
  seriesIds: string[],
  page: number,
  periods: string[]
): Promise<PaginatedModelLivePreviewSeries> => {
  const {
    data: { modelLivePreviewSeries }
  } = (await GraphQL.query({
    query: gql`
      query modelLivePreviewSeriesQuery($seriesIds: [Int], $companyId: String, $periods: [String]) {
        modelLivePreviewSeries(seriesIds: $seriesIds, companyId: $companyId, periods: $periods) {
          series {
            id
            name
            rowNumber
            fundamentals {
              id
              value
              normalizedValue
              period
              context
            }
          }
        }
      }
    `,
    variables: {
      seriesIds,
      companyId,
      periods
    },
    fetchPolicy: 'no-cache'
  })) as { data: { modelLivePreviewSeries: ModelLivePreviewSeries } };
  return { data: modelLivePreviewSeries, page };
};
