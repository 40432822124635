import React, { useState } from 'react';

import useLocal from 'localization';

import Props, { reportFundamentalErrorType } from './types';
import useStyles from './style';
import { Box, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import ToggleOffTwoToneIcon from '@material-ui/icons/ToggleOffTwoTone';
import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
import Badge from '@material-ui/core/Badge';
import { ErrorOutline } from '@material-ui/icons';
import { ReportFundamentalErrorModal } from './ReportFundamentalErrorModal';
import { GraphQL, gql } from 'core';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '@material-ui/core/CircularProgress';

const REPORT_FUNDAMENTAL_ERROR_MUTATION = gql`
  mutation reportFundamentalError($fundamentalId: Int, $errorType: String, $description: String) {
    reportFundamentalError(
      fundamentalId: $fundamentalId
      errorType: $errorType
      description: $description
    ) {
      success
      error
      id
    }
  }
`;

const USER_TYPE_QUERY = gql`
  {
    userType
  }
`;

const WRONG_DATA_TAGGED = 'wrong data tagged';
const PAYING_USER = 'paying';
const TRIAL_USER = 'trial';
const INTERNAL_USER = 'internal';
const ENTERPRISE_TRIAL = 'enterprise_trial';
const ALLOWED_USER_TYPES = [PAYING_USER, TRIAL_USER, INTERNAL_USER, ENTERPRISE_TRIAL];

const getUserType = () =>
  GraphQL.query<{ userType: string }>({ query: USER_TYPE_QUERY }).then((res) => res.data.userType);

const TvSidebar: React.FC<Props> = ({
  fundamentlInfo,
  printAsPdf,
  locateDatapoint,
  isDocument,
  snippetMode,
  onToggleMode,
  id
}: Props) => {
  const classes = useStyles();
  const {
    company: { name, ticker },
    document,
    type
  } = fundamentlInfo;
  const srcFilling = useLocal('src_filling');
  const srcExhibit = useLocal('src_exhibit');
  const srcFiledOn = useLocal('src_filed_on');
  const srcSaveAsPdf = useLocal('src_save_as_pdf');
  const srcLocateDataPoint = useLocal('src_locate_data_point');
  const srcViewOnSecEdger = useLocal('src_view_on_sec_edger');
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [isReportFundamentalModalOpen, setIsReportFundamentalModalOpen] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const { data: userType, isLoading: isUserTypeLoading } = useQuery(['userType'], getUserType);
  const [reportId, setReportId] = useState<string>();

  const reportFundamentalError = async () => {
    setIsReportLoading(true);
    const { data } = await GraphQL.mutate<{ reportFundamentalError: reportFundamentalErrorType }>({
      mutation: REPORT_FUNDAMENTAL_ERROR_MUTATION,
      variables: {
        fundamentalId: id,
        errorType: WRONG_DATA_TAGGED
      }
    });

    setIsReportLoading(false);
    if (data && data?.reportFundamentalError?.success && !!data.reportFundamentalError?.id) {
      setIsReportFundamentalModalOpen(true);
      setReportId(data.reportFundamentalError.id);
    }
  };

  return (
    <Box height={{ sm: 'auto', md: '80vh' }} bgcolor="white.main" displayPrint="none">
      <ReportFundamentalErrorModal
        isOpen={isReportFundamentalModalOpen}
        handleClose={() => setIsReportFundamentalModalOpen(false)}
        reportId={reportId}
      />
      <Box p={1} pb={3} fontWeight="fontWeightBold" data-testid="company-name">
        <h2>
          {ticker} - {name}
        </h2>
        {type !== 'COMPOSITE' && document && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {srcFilling}
            </Grid>
            <Grid item xs={6}>
              {document?.filingType}
            </Grid>

            <Grid item xs={6}>
              {srcExhibit}
            </Grid>
            <Grid item xs={6}>
              {document?.documentType}
            </Grid>

            <Grid item xs={6}>
              {srcFiledOn}
            </Grid>
            <Grid item xs={6}>
              {document?.reportedDate}
            </Grid>
          </Grid>
        )}
      </Box>

      <Box
        marginTop={{ md: 15, sm: 0 }}
        fontWeight="fontWeightBold"
        display="flex"
        flexDirection={{ xs: 'row', sm: 'column' }}
        justifyContent="space-between"
        p={1}
      >
        {!isUserTypeLoading && !!userType && ALLOWED_USER_TYPES.includes(userType) && (
          <Button
            className={classes.redBtn}
            size="large"
            variant="contained"
            onClick={reportFundamentalError}
            disabled={isReportLoading}
          >
            <ErrorOutline className={classes.icons} />
            <Badge badgeContent={'New'} color="error" classes={{ root: classes.badgeRoot }}>
              Report an Issue
              {isReportLoading && (
                <CircularProgress size={24} classes={{ colorPrimary: classes.progressBar }} />
              )}
            </Badge>
          </Button>
        )}
        <Button className={classes.blueBtn} size="large" variant="contained" onClick={onToggleMode}>
          {snippetMode ? (
            <ToggleOffTwoToneIcon className={classes.icons} />
          ) : (
            <ToggleOnTwoToneIcon className={classes.icons} />
          )}
          Toggle {snippetMode ? 'Document mode' : 'Snippet mode'}
        </Button>

        {type !== 'COMPOSITE' && (
          <>
            {!isDocument && (
              <Button
                className={classes.blueBtn}
                size="large"
                variant="contained"
                onClick={locateDatapoint}
              >
                <MyLocationIcon className={classes.icons} />
                {!isSmallScreen && srcLocateDataPoint}
              </Button>
            )}

            {document && (
              <Button
                target="_blank"
                href={document.url}
                className={classes.blueBtn}
                size="large"
                variant="contained"
              >
                <VisibilityIcon className={classes.icons} />
                {!isSmallScreen && srcViewOnSecEdger}
              </Button>
            )}
          </>
        )}
        <Button onClick={printAsPdf} className={classes.yellowBtn} size="large" variant="contained">
          <SaveIcon className={classes.icons} />
          {!isSmallScreen && srcSaveAsPdf}
        </Button>
      </Box>
    </Box>
  );
};

export default TvSidebar;
