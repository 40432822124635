import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttons: {
    marginRight: '1em'
  },
  container: {
    flex: 1,
    '& > div': {
      height: 'unset !important'
    },
    '& .ReactVirtualized__Grid, .ReactVirtualized__Grid__innerScrollContainer': {
      overflow: 'visible !important'
    },
    margin: 0,
    padding: theme.spacing(6),
    display: 'flex',
    width: '100%'
  },
  mainContainer: {
    display: 'flex',
    flex: 1,
    width: '100%'
  },
  loadMore: {
    position: 'absolute',
    bottom: 8
  }
}));
