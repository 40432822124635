import React from 'react';
import DownloadModelPopup from 'components/DownloadModelPopup';

import { DownloadModelTypes } from './duck';

const DownloadModel: React.FC<DownloadModelTypes.DownloadModelProps> = ({
  isOpened,
  onClose
}: DownloadModelTypes.DownloadModelProps) => {
  return <DownloadModelPopup isOpened={isOpened} onClose={onClose} />;
};

export default DownloadModel;
