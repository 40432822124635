import { datadogRum } from '@datadog/browser-rum';

if (
  process.env.REACT_APP_DATADOG_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
  process.env.REACT_APP_ENV &&
  process.env.NODE_ENV !== 'development'
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'marketplace',
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}
