import actionTypes, { RegisterState, actionCreatorTypes } from './types';

const initialState: RegisterState = {
  isLoading: false,
  isError: false,
  msg: [],
  success: false
};

const reducer = (
  state: RegisterState = initialState,
  action: actionCreatorTypes
): RegisterState => {
  switch (action.type) {
    case actionTypes.REGISTER_TRIAL_PENDING:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case actionTypes.REGISTER_TRIAL_ERROR:
      return {
        ...state,
        isLoading: false,
        msg: action.msg ?? null,
        isError: true
      };
    case actionTypes.REGISTER_TRIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        success: true
      };
    default:
      return state;
  }
};

export default reducer;
