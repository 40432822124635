import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('xl')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '3em'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0
    }
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 0
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  }
}));
