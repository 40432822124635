const actionTypes = {
  REGISTER_TRIAL: 'marketplace/Register/REGISTER_TRIAL',
  REGISTER_TRIAL_PENDING: 'marketplace/Register/REGISTER_TRIAL_PENDING',
  REGISTER_TRIAL_ERROR: 'marketplace/Register/REGISTER_TRIAL_ERROR',
  REGISTER_TRIAL_SUCCESS: 'marketplace/Register/REGISTER_TRIAL_SUCCESS'
};

export type registerField = {
  email: string;
  password: string;
  cnfPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};
export type RegisterProps = {
  // Add Props type here
};
export type RegisterState = {
  //Add reducer state type here
  isLoading: boolean;
  isError: boolean;
  success: boolean;
  msg: string[] | null;
};

export type registerTrialAction = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  phoneNumber: string
) => {
  type: typeof actionTypes.REGISTER_TRIAL;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
};

export type registerTrialPendingAction = () => {
  type: typeof actionTypes.REGISTER_TRIAL_PENDING;
};

export type registerTrialErrorAction = (
  msg: string[]
) => {
  type: typeof actionTypes.REGISTER_TRIAL_ERROR;
  msg: string[];
};

export type registerTrialSuccessAction = () => {
  type: typeof actionTypes.REGISTER_TRIAL_SUCCESS;
};

export type actionCreatorTypes = {
  type: string;
  msg?: string[];
};

export default actionTypes;
