import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork, select, SelectEffect } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import localizationReducer from 'localization/reducer';
import LoginPageReducer from 'pages/Login/duck';
import { LoginPageSaga } from 'pages/Login/duck';
import ModelsReducer from 'pages/Models/duck';
import { ModelsSaga } from 'pages/Models/duck';
import IndustryModelReducer from 'pages/IndustryModels/duck';
import { IndustryModelsSaga } from 'pages/IndustryModels/duck';
import RequestModelReducer from 'pages/RequestModel/duck';
import { RequestModelSaga } from 'pages/RequestModel/duck';
import { RequestNewModelSaga } from 'pages/RequestNewModel/duck';
import RequestNewModelReducer from 'pages/RequestNewModel/duck';
import ApiSettingsReducer from 'pages/ApiSettings/duck';
import { ApiSettingsSaga } from 'pages/ApiSettings/duck';
import { ExcelPluginSaga } from 'pages/ExcelPlugin/duck';
import ExcelPluginReducer from 'pages/ExcelPlugin/duck';
import ForgetPasswordReducer from 'pages/ForgetPassword/duck';
import { ForgetPasswordSaga } from 'pages/ForgetPassword/duck';
import ResetPasswordReducer from 'pages/ResetPassword/duck';
import { ResetPasswordSaga } from 'pages/ResetPassword/duck';
import TagViewerReducer from 'pages/TagViewer/duck';
import { TagViewerSaga } from 'pages/TagViewer/duck';
import SignUpReducer from 'pages/SignUp/duck';
import { SignUpSaga } from 'pages/SignUp/duck';
import RegisterReducer from 'pages/Register/duck';
import ThankyouVerifyEmailReducer from 'pages/ThankyouVerifyEmail/duck';
import { ThankyouVerifyEmailSaga } from 'pages/ThankyouVerifyEmail/duck';
import InquireHerePopupReducer from 'pages/InquireHerePopup/duck';
import { RegisterSaga } from 'pages/Register/duck';
import UserDetailReducer from 'userDetail';
import { UserDetailSaga } from 'userDetail';
import { all } from '@redux-saga/core/effects';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const rootReducer = combineReducers({
  localizationReducer,
  LoginPageReducer,
  ModelsReducer,
  IndustryModelReducer,
  RequestModelReducer,
  RequestNewModelReducer,
  ApiSettingsReducer,
  ExcelPluginReducer,
  ForgetPasswordReducer,
  ResetPasswordReducer,
  TagViewerReducer,
  SignUpReducer,
  RegisterReducer,
  UserDetailReducer,
  ThankyouVerifyEmailReducer,
  InquireHerePopupReducer
});
function* rootSaga() {
  yield all([
    fork(LoginPageSaga),
    fork(ModelsSaga),
    fork(IndustryModelsSaga),
    fork(RequestModelSaga),
    fork(RequestNewModelSaga),
    fork(ApiSettingsSaga),
    fork(ExcelPluginSaga),
    fork(ForgetPasswordSaga),
    fork(ResetPasswordSaga),
    fork(TagViewerSaga),
    fork(SignUpSaga),
    fork(RegisterSaga),
    fork(ThankyouVerifyEmailSaga),
    fork(UserDetailSaga)
  ]);
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export function selectState<T>(selector: (s: RootState) => T): SelectEffect {
  return select(selector);
}

export default store;
