import { put, takeLatest } from 'redux-saga/effects';
import { GraphQL, gql, History } from 'core';
import { selectState } from 'store';
import actionTypes, { getCompanyListAction, modelRequestAction } from './types';
import actions from './actions';
import { getLocalValue } from 'localization';
import Mixpanel from 'mixPanel';
import { getUserEmail } from 'core';

export function* getCompanyListSaga({ keyword }: ReturnType<getCompanyListAction>) {
  yield put(actions.setCompanyListLoading());
  try {
    const {
      data: { companiesV2 }
    } = yield GraphQL.query({
      query: gql`
        query getCompanyQuery($keyword: String!) {
          companiesV2(keyword: $keyword) {
            id
            ticker
            name
          }
        }
      `,
      variables: { keyword },
      fetchPolicy: 'no-cache'
    });

    yield put(actions.setCompanyList(companiesV2));
  } catch (error) {
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    yield put(actions.setCompanyListError(errorMsg));
  }
}

function* modelRequestSaga({ companyId }: ReturnType<modelRequestAction>) {
  try {
    const trackPayload: { companyId: string; email?: string } = { companyId };
    const userEmail = getUserEmail();
    if (userEmail) {
      trackPayload.email = userEmail;
    }
    Mixpanel.track('request-model:submit', trackPayload);
    const {
      data: {
        createModelRequest: { success }
      }
    } = yield GraphQL.mutate({
      mutation: gql`
        mutation createModelRequestMutation($companyId: String!, $comment: String!) {
          createModelRequest(companyId: $companyId, comment: $comment) {
            success
          }
        }
      `,
      variables: {
        companyId,
        comment: 'request from marketplace'
      },
      fetchPolicy: 'no-cache'
    });

    if (success) {
      yield put(actions.setSelectedCompanyId(null));
      const msg: string = yield selectState<string | undefined>((state) =>
        getLocalValue(state, 'request_success')
      );
      alert(msg);
      History.goBack();
    }
  } catch (error) {
    yield put(actions.setSelectedCompanyId(null));
    const errorMsg: string = yield selectState<string | undefined>((state) =>
      getLocalValue(state, 'error_msg')
    );
    alert(errorMsg);
    History.goBack();
  }
}

export default function* watchIncrementAsync() {
  yield takeLatest(actionTypes.GET_COMPANY_LIST, getCompanyListSaga);
  yield takeLatest(actionTypes.MODEL_REQUEST, modelRequestSaga);
}
