import RegisterReducer from './reducer';
import RegisterSelectors from './selector';
import RegisterSaga from './saga';
import * as RegisterTypes from './types';
import actionTypes from './types';
import actions from './actions';

export { RegisterSelectors, RegisterSaga, RegisterTypes, actionTypes, actions };

export default RegisterReducer;
