import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { useQuery } from '@tanstack/react-query';
import { getCompany } from '../LiveModelPreview/service';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { getFundamentalForSeries } from './duck/service';
import { Fundamental, Series } from '../LiveModelPreview/types';
import { FormControlLabel, Link, Stack, Checkbox } from '@mui/material';
import {
  formatFormula,
  getSeriesIdsFromFormula,
  getValue,
  getFormulaObject,
  ALPHABETS,
  evaluate
} from './duck/utils';
import uuid from 'react-uuid';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import Mixpanel from '../../mixPanel';

const formatNumber = (number: number): string => {
  if (Number.isInteger(number)) {
    return number.toString();
  } else {
    return number.toPrecision(3);
  }
};

const RolledUpNumbers = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('company_id');
  const period = urlParams.get('period');
  const formula = urlParams.get('formula');
  const classes = useStyles();

  const [isYTD, setIsYTD] = useState(false);

  const { data: company, isLoading } = useQuery(
    ['company', companyId],
    () => (companyId ? getCompany(companyId) : undefined),
    {
      enabled: !!companyId,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  );
  const seriesIds = useMemo(() => (formula ? getSeriesIdsFromFormula(formula) : []), [formula]);

  const { isLoading: isSeriesLoading, data: series } = useQuery(
    ['series', { companyId, seriesIds, period }],
    () =>
      companyId && seriesIds && !!period
        ? getFundamentalForSeries(companyId, seriesIds, 1, [period])
        : undefined,
    {
      enabled: !!companyId && !!seriesIds && !!period,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: false
    }
  );

  const seriesById = useMemo(() => {
    const seriesById: { [id: string]: Series } = {};
    series?.data.series.forEach((series, index) => {
      seriesById[series.id] = series;
    });
    return seriesById;
  }, [seriesIds, series?.data]);

  const resultRow = useMemo(() => {
    if (!series?.data?.series || !formula) {
      return {};
    }
    const formulaWithVariables = formatFormula(formula);
    const formulaObject = getFormulaObject(formulaWithVariables);
    let resultRowTitle = String(formulaWithVariables);

    seriesIds.forEach((seriesId, index) => {
      resultRowTitle = resultRowTitle.replace(
        `[${ALPHABETS[index]}${seriesId}]`,
        seriesById[seriesId]?.name ?? ''
      );
    });
    const fundamentalsBySeriesId: { [id: string]: Fundamental[] } = {};
    series.data.series.forEach((series) => {
      fundamentalsBySeriesId[series.id] = series.fundamentals;
    });

    return {
      name: resultRowTitle,
      fundamentals: [
        {
          isResult: true,
          value: formulaObject
            ? formatNumber(evaluate(formulaObject, fundamentalsBySeriesId, seriesIds, isYTD))
            : null
        }
      ],
      id: uuid()
    };
  }, [formula, series?.data?.series, isYTD]);

  useEffect(() => {
    if (seriesIds && period && formula && companyId) {
      Mixpanel.track('Marketplace:rollup_number_view', { formula, period, companyId, seriesIds });
    }
  }, [seriesIds, period, formula, companyId]);

  const columns = useMemo(() => {
    const cols: GridColDef[] = [
      {
        field: 'name',
        headerName: 'Row Label',
        filterable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          let name = '';
          const rowIndex = params.api.getRowIndex(params.row.id);
          const seriesIdSet = new Set(seriesIds);
          if (rowIndex < seriesIdSet.size) {
            name = seriesById[seriesIds[rowIndex]]?.name;
          } else if (resultRow && resultRow.name) {
            name = resultRow.name;
          }
          return name;
        }
      },
      {
        field: 'fundamentals',
        headerName: period ?? '',
        filterable: false,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
          const rowIndex = params.api.getRowIndex(params.row.id);
          let fundamental;
          const seriesIdSet = new Set(seriesIds);
          if (rowIndex < seriesIdSet.size) {
            fundamental = seriesById[seriesIds[rowIndex]]?.fundamentals[0] ?? {};
          } else if (resultRow && resultRow.fundamentals) {
            fundamental = resultRow?.fundamentals[0] as Fundamental;
          }

          if (fundamental && !fundamental.isResult && fundamental.id !== '-1') {
            return (
              <Link href={`/src/${fundamental.id}`} target={'_blank'}>
                {getValue(fundamental, isYTD)}
              </Link>
            );
          } else if (fundamental && fundamental.id === '-1') {
            return 'Not Released';
          } else if (fundamental) {
            return isFinite(parseFloat(fundamental.value)) ? fundamental.value : 'No Result';
          }
          return '-';
        }
      }
    ];
    return cols;
  }, [period, seriesById, seriesIds, resultRow, isYTD]);

  if (companyId && period && formula) {
    return (
      <Box className={classes.pageContainer}>
        {isLoading || isSeriesLoading ? (
          <Box className={classes.progressWrapper}>
            <CircularProgress variant={'indeterminate'} />
          </Box>
        ) : (
          <>
            {!!company?.name && !!company?.ticker && (
              <Typography variant={'h5'}>
                {company.name} ({company.ticker})
              </Typography>
            )}

            <Stack direction={'row'} width={'100%'} alignItems={'center'}>
              <FormControlLabel
                sx={{ ml: 2 }}
                control={
                  <Checkbox
                    checked={isYTD}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setIsYTD(e.target.checked)}
                  />
                }
                label={'Show YTD values'}
              />
              <FormControlLabel
                sx={{ ml: 2 }}
                control={
                  <Checkbox
                    checked={!isYTD}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setIsYTD(!e.target.checked)}
                  />
                }
                label={'Show Quaterized values'}
              />
            </Stack>
            <Box className={classes.tableWrapper}>
              <DataGrid
                autoHeight={true}
                columns={columns}
                rows={[...(series?.data?.series ?? []), resultRow] ?? []}
                getRowId={(row) => row.id}
                isRowSelectable={() => false}
                pagination={undefined}
                autoPageSize={true}
              />
            </Box>
          </>
        )}
      </Box>
    );
  }
  return (
    <Box className={classes.pageContainer}>
      <Typography variant={'h5'}>No Data</Typography>
    </Box>
  );
};

export default RolledUpNumbers;
