import actionTypes, {
  registerTrialAction,
  registerTrialPendingAction,
  registerTrialErrorAction,
  registerTrialSuccessAction
} from './types';

const registerTrial: registerTrialAction = (firstName, lastName, email, password, phoneNumber) => ({
  type: actionTypes.REGISTER_TRIAL,
  firstName,
  lastName,
  email,
  password,
  phoneNumber
});

const registerTrialPending: registerTrialPendingAction = () => ({
  type: actionTypes.REGISTER_TRIAL_PENDING
});

const registerTrialError: registerTrialErrorAction = (msg) => ({
  type: actionTypes.REGISTER_TRIAL_ERROR,
  msg
});

const registerTrialSuccess: registerTrialSuccessAction = () => ({
  type: actionTypes.REGISTER_TRIAL_SUCCESS
});

export default {
  registerTrial,
  registerTrialPending,
  registerTrialError,
  registerTrialSuccess
};
